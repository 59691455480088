import Velocity from "velocity-animate";

//
// !!! Depreciated: As we are now using AlpineJS instead
//

(function (cash) {
    "use strict";

    // Toggle filters
    cash("body").on("click", "#toggle-filters-off", function () {
        let elementId = "#" + cash(this).data("target");
        cash(this).hide();
        cash("#toggle-filters-on").show();

        // cash(elementId).toggle();
        Velocity(cash(elementId), "slideUp", { duration: 450 });
        Velocity(cash(elementId), "fadeOut", { duration: 350 });

        // Probably need to upgrade Velocity to use RunSequence
        // var mySequence = [
        //     { e: cash(elementId), p: { translateX: 100 }, o: { duration: 1000 } },
        //     { e: cash(elementId), p: { translateX: 200 }, o: { duration: 1000, sequenceQueue: false } },
        //     { e: cash(elementId), p: { translateX: 300 }, o: { duration: 1000 } }
        // ];
        // Velocity.RunSequence(mySequence);
    });
    cash("body").on("click", "#toggle-filters-on", function () {
        let elementId = "#" + cash(this).data("target");
        cash(this).hide();
        cash("#toggle-filters-off").show();

        // cash(elementId).toggle();
        Velocity(cash(elementId), "slideDown", { duration: 450 });
        Velocity(cash(elementId), "fadeIn", { duration: 450 });
    });


    // Toggle filters
    cash("body").on("click", ".toggle-plus", function () {
        let elementId = cash(this).data("target");
        let btw_to_show = cash(this).data("target-button");

        cash(this).hide();
        cash(btw_to_show).show();
        cash(elementId).show();

        // Velocity(cash(elementId), "slideDown", { duration: 450 });
        // Velocity(cash(elementId), "fadeIn", { duration: 450 });
    });
    cash("body").on("click", ".toggle-minus", function () {
        let elementId = cash(this).data("target");
        let btw_to_show = cash(this).data("target-button");

        cash(this).hide();
        cash(btw_to_show).show();
        cash(elementId).hide();

        // Velocity(cash(elementId), "slideUp", { duration: 450 });
        // Velocity(cash(elementId), "fadeOut", { duration: 350 });
    });
})(cash);
