(function (cash) {
    "use strict";

    // On loading set the button to the right value
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        cash(".dark-switcher").find(".side-menu__title").text("Light Mode");
        cash(".dark-switcher-moon").addClass("hidden");
        cash(".dark-switcher-sun").removeClass("hidden");
    } else {
        cash(".dark-switcher").find(".side-menu__title").text("Dark Mode");
        cash(".dark-switcher-sun").addClass("hidden");
        cash(".dark-switcher-moon").removeClass("hidden");
    }

    // Copy original code
    cash(".dark-mode-switcher").on("click", function () {
        let switcher = cash(this).find(".dark-mode-switcher__toggle");
        if (cash(switcher).hasClass("dark-mode-switcher__toggle--active")) {
            cash(switcher).removeClass("dark-mode-switcher__toggle--active");
        } else {
            cash(switcher).addClass("dark-mode-switcher__toggle--active");
        }

        // old code which redirect to URL
        // setTimeout(() => {
        //     let link = cash(".dark-mode-switcher").data("url");
        //     window.location.href = link;
        // }, 500);

        // New switch
        let html = cash(document).find("html");

        if (cash(html).hasClass("dark")) {
            cash(html).removeClass("dark");
            localStorage.theme = 'light'
            cash(this).find(".side-menu__title").text("Light Mode");
        } else {
            cash(html).addClass("dark");
            localStorage.theme = 'dark'
            cash(this).find(".side-menu__title").text("Dark Mode");
        }
    });

    // To make the switch button activate if dark mode is on
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        // dark_switch_class = "dark-mode-switcher__toggle--active";
        cash(".dark-mode-switcher__toggle").addClass("dark-mode-switcher__toggle--active");
    } else {
        // dark_switch_class = "dark-mode-switcher__toggle";
        cash(".dark-mode-switcher__toggle").removeClass("dark-mode-switcher__toggle--active");
    }

    // New code
    cash(".dark-switcher").on("click", function () {
        let html = cash(document).find("html");

        if (cash(html).hasClass("dark")) {
            cash(html).removeClass("dark");
            localStorage.theme = 'light';
            cash(this).find(".side-menu__title").text("Dark Mode");
            cash(".dark-switcher-sun").addClass("hidden");
            cash(".dark-switcher-moon").removeClass("hidden");
        } else {
            cash(html).addClass("dark");
            localStorage.theme = 'dark';
            cash(this).find(".side-menu__title").text("Light Mode");
            cash(".dark-switcher-moon").addClass("hidden");
            cash(".dark-switcher-sun").removeClass("hidden");
        }
    });
})(cash);
