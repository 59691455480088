import {RequestResult} from '~/js/data-import'
import Toastify from "toastify-js";

export class MediaTab {
  static MEDIAL_ALL_URL = '/message-media/org/all'
  static ADD_MEDIA_URL = '/message-media/'
  static DELETE_MEDIA_URL = '/message-media/:id'

  constructor() {
    this.isLoading = false
    this.events = {}
  }

  async init(alpine_instance) {
  }

  onLoaded() {
  }

  addMedia(media) {
    this.events.addMedia(media)
  }

  removeMedia(mediaId) {
    this.events.removeMedia(mediaId)
  }

  addEvent(key, callback) {
    if (!['addMedia', 'removeMedia'].includes(key)) {
      throw 'Invalid key. Allowed events = ["addMedia", "removeMedia"]'
    }
    this.events[key] = callback
  }

  async request(url, config = null) {
    let res = null
    try {
      if (config === null) {
        config = {method: 'GET'}
      }
      this.isLoading = true
      res = await fetch(`${url}`, config)
      this.isLoading = false
      const data = await res.json()
      return new RequestResult(data, res.status)
    } catch (e) {
      console.error(e)
      return new RequestResult(null, res?.status)
    }
  }

  constructMediaURL(mediaUrl) {
    return `${window.location.origin}${mediaUrl}`
  }

  reset() {
  }
  alert(message) {
    Toastify({
      text: message,
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: "top",
      position: "center",
      stopOnFocus: true,
      className: "toastify-content",
    }).showToast();
  }
}
